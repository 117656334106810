import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo/SEO";
import marketplaceExampleCards from "../components/marketplace-screen/MarketplaceExampleCards";
import MarketplaceScreen from "../components/marketplace-screen";
import { useMarketplacePage } from "../hooks/useMarketplacePage";

const MarketplacePage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("marketplace.title")} />
      <MarketplaceScreen
        cards={marketplaceExampleCards.cards}
        useMarketplacePage={useMarketplacePage}
      />
    </Layout>
  );
};

export default MarketplacePage;
