import { useContext, useState } from "react";
import { SnackBarContext } from "../providers/SnackbarProvider";
import { request } from "../util/request";
import { CreateContainerFields } from "../components/controls/CreateContainer";
import { ContainerHelmChartName } from "../components/controls/CreateContainer";

export interface CreateContainerRequest {
  customerId: string;
  helmChartName: ContainerHelmChartName;
  releaseName: string;
  description: string;
}

export const useMarketplacePage = () => {
  const defaultHelmChartName: ContainerHelmChartName = "rabbitmq-7.6.6.tgz";
  const [createContainerOpen, setCreateContainerOpen] = useState(false);
  const [helmChartName, setHelmChartName] = useState<ContainerHelmChartName>(
    defaultHelmChartName
  );
  const snackbar = useContext(SnackBarContext);

  const handleCreateContainerOpen = (
    helmChartName: ContainerHelmChartName
  ): void => {
    setHelmChartName(helmChartName);
    setCreateContainerOpen(true);
  };
  const handleCreateContainerClose = (): void => {
    setHelmChartName(defaultHelmChartName);
    setCreateContainerOpen(false);
  };

  const handleCreateContainerChange = async (
    data: CreateContainerFields
  ): Promise<void> => {
    try {
      const body: CreateContainerRequest = {
        customerId: `${process.env.GATSBY_CUSTOMER_ID}`,
        helmChartName: data.helmChartName,
        releaseName: data.releaseName,
        description: data.description,
      };

      await request(
        `${process.env.GATSBY_API_CREATE_CONTAINER}?key=${process.env.GATSBY_API_KEY}`,
        {
          method: "POST",
          body: JSON.stringify(body),
          mode: "cors",
        }
      );

      snackbar.showFeedback({
        message: "Successfully Created Container",
        duration: 3500,
      });
      handleCreateContainerClose();
    } catch (error) {
      snackbar.showFeedback({
        message: "Failed to create Container",
        duration: 3500,
        severity: "error",
      });
      console.error(error);
    }
  };

  return {
    createContainerOpen,
    handleCreateContainerOpen,
    handleCreateContainerClose,
    handleCreateContainerChange,
    helmChartName,
  };
};

export default useMarketplacePage;
