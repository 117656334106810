import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React, { FunctionComponent } from "react";
import defaultUseMarketplacePage from "../../hooks/useMarketplacePage";
import {
  ContainerHelmChartName,
  CreateContainer,
} from "../controls/CreateContainer";
import MarketplaceCard from "../marketplace-card/MarketplaceCard";
import { MarketplaceCardData } from "./MarketplaceExampleCards";

interface MarketplaceScreenProps {
  cards: MarketplaceCardData[];
  useMarketplacePage: typeof defaultUseMarketplacePage;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(1),
    },
    mainGrid: {
      padding: theme.spacing(0, 2, 0, 2),
    },
    logo: {
      margin: theme.spacing(0, 1, 2),
    },
    titlePadding: {},
  })
);

const text = {
  title: "Marketplace",
};

const MarketplaceScreen: FunctionComponent<MarketplaceScreenProps> = ({
  cards,
  useMarketplacePage,
}) => {
  const classes = useStyles();
  const {
    createContainerOpen,
    handleCreateContainerOpen,
    handleCreateContainerClose,
    handleCreateContainerChange,
    helmChartName,
  } = useMarketplacePage();

  const handleCardClick = (helmChartName?: ContainerHelmChartName) => {
    if (helmChartName) {
      handleCreateContainerOpen(helmChartName);
    }
  };

  return (
    <Box flex={1} className={classes.mainGrid}>
      <Grid container direction={"row"} alignItems={"baseline"}>
        <Grid item>
          <ShoppingCartIcon className={classes.logo} fontSize={"default"} />
          <Typography variant={"h5"}>{text.title}</Typography>
        </Grid>
      </Grid>
      <Grid container direction={"row"}>
        {cards?.map((card, index) => (
          <Grid className={classes.grid} key={index}>
            <MarketplaceCard {...card} handleClick={handleCardClick} />
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={createContainerOpen}
        onClose={handleCreateContainerClose}
        aria-labelledby="create-container-dialog"
      >
        <DialogContent>
          <CreateContainer
            onCancel={handleCreateContainerClose}
            onCreate={handleCreateContainerChange}
            selectedHelmChart={helmChartName}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MarketplaceScreen;
