import { useState } from "@hookstate/core";
import { Validation } from "@hookstate/validation";
import Button from "@material-ui/core/Button";
import { CardProps } from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";

export type ContainerHelmChartName =
  | "rabbitmq-7.6.6.tgz"
  | "redis-10.7.16.tgz"
  | "mongodb-9.1.1.tgz"
  | "hipstershop-0.1.0.tgz";

type HelmChartMenuOptions = {
  chartName: ContainerHelmChartName;
  label: string;
}[];

const helmCharts: HelmChartMenuOptions = [
  {
    chartName: "rabbitmq-7.6.6.tgz",
    label: "RabbitMQ",
  },
  {
    chartName: "redis-10.7.16.tgz",
    label: "Redis",
  },
  {
    chartName: "mongodb-9.1.1.tgz",
    label: "MongoDB",
  },
  {
    chartName: "hipstershop-0.1.0.tgz",
    label: "Online Boutique",
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      maxWidth: 350,
    },
    fields: {
      marginTop: theme.spacing(1.5),
      width: "100%",
    },
    formControl: {
      minWidth: 120,
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export interface CreateContainerFields {
  helmChartName: ContainerHelmChartName;
  releaseName: string;
  releaseNameStarted: boolean;
  description: string;
}

export interface CreateContainerProps extends Partial<CardProps> {
  onCreate?: (data: CreateContainerFields) => void;
  onCancel?: () => void;
  selectedHelmChart?: ContainerHelmChartName;
}

export const CreateContainer: React.FC<CreateContainerProps> = ({
  children,
  onCreate,
  onCancel,
  selectedHelmChart,
}) => {
  const classes = useStyles();
  const formSubmitted = useState<boolean>(false);
  const formState = useState<CreateContainerFields>({
    helmChartName: selectedHelmChart || "rabbitmq-7.6.6.tgz",
    releaseName: "",
    releaseNameStarted: false,
    description: "",
  });
  formState.attach(Validation);

  Validation(formState.releaseName).validate((releaseName) => {
    return (
      releaseName !== "" &&
      Boolean(releaseName.match(/^[a-z0-9]/)) &&
      Boolean(releaseName.match(/[a-z0-9]$/)) &&
      !releaseName.match(/[^a-z0-9-.]+/)
    );
  }, "Release name is required, can only container lowercase letters, numbers, . or -, and must start and end with a lowercase letter or number");

  const handleSubmit = () => {
    if (!Validation(formState.releaseName).valid()) {
      formState.releaseNameStarted.set(true);
      return;
    }

    formSubmitted.set(true);

    onCreate && onCreate({ ...formState.value });
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const releaseNameError =
    formState.releaseNameStarted.value &&
    !Validation(formState.releaseName).valid() &&
    Validation(formState.releaseName).errors()[0]?.message;

  return (
    <>
      <CardContent>
        <Typography variant={"h4"} component={"h2"} color="textSecondary">
          Create Container
        </Typography>
        <TextField
          className={classes.fields}
          id="create-release-name"
          label="Release Name"
          variant="outlined"
          value={formState.releaseName.value}
          error={Boolean(releaseNameError)}
          helperText={releaseNameError}
          onChange={(event) =>
            formState.merge({
              releaseName: event.target.value.trim(),
              releaseNameStarted: true,
            })
          }
        />
        <FormControl variant="outlined" className={classes.fields}>
          <InputLabel id="create-virtual-machine-helm-chart">
            Helm Chart
          </InputLabel>
          <Select
            labelId="helm-chart-name-select-label"
            id="helm-chart-name-select-outlined"
            value={formState.helmChartName.value}
            onChange={(event) =>
              formState.helmChartName.set(
                event.target.value as ContainerHelmChartName
              )
            }
            label="Helm Chart Name"
            disabled={!!selectedHelmChart}
          >
            {helmCharts.map((hcm) => {
              return (
                <MenuItem key={hcm.chartName} value={hcm.chartName}>
                  {hcm.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          className={classes.fields}
          id="create-container-description"
          label="Description"
          variant="outlined"
          multiline={true}
          value={formState.description.value}
          onChange={(event) =>
            formState.merge({
              description: event.target.value,
            })
          }
        />
        {children}
      </CardContent>
      <CardActions>
        <Button onClick={() => handleCancel()}>Cancel</Button>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => handleSubmit()}
          disabled={
            !!formSubmitted.get() || !!Validation(formState).errors().length
          }
        >
          Create
        </Button>
      </CardActions>
    </>
  );
};
