import { Box, ButtonBase, Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";
import { ContainerHelmChartName } from "../controls/CreateContainer";
import { MarketplaceCardData } from "../marketplace-screen/MarketplaceExampleCards";

export interface MarketplaceCardProps extends MarketplaceCardData {
  handleClick: (helmChartName?: ContainerHelmChartName) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: 240,
      minHeight: 276,
    },
    image: {
      width: 80,
      height: 40,
    },
    titleText: {
      color: "black",
    },
    text: {
      color: "grey",
    },
    typeGrid: {
      margin: theme.spacing(2, 0, 0),
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    hover: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

const text = {
  type: "Type",
};

const MarketplaceCard: FunctionComponent<MarketplaceCardProps> = (props) => {
  const classes = useStyles();

  return (
    <Box flex={1}>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={2}
          className={classes.hover}
          onClick={() => {
            props.handleClick(props.helmChartName);
          }}
        >
          <Grid item xs={12} id={"logo"}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <ButtonBase className={classes.image}>
                <img
                  className={classes.img}
                  alt="logo unavailable"
                  src={props.logo}
                />
              </ButtonBase>
            </Grid>
          </Grid>
          <Grid item xs={12} id={"title"}>
            <Typography variant={"body1"} className={classes.titleText}>
              {props.title}
            </Typography>
            <Typography variant={"body2"} className={classes.text}>
              {props.subtitle}
            </Typography>
          </Grid>
          <Grid item xs={12} id={"description"}>
            <Typography variant={"body2"} className={classes.text}>
              {props.description}
            </Typography>
          </Grid>
          {props.helmChartName && (
            <Grid item xs={12} id={"install-text"}>
              <Typography variant={"body2"} color="primary">
                INSTALL
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} id={"type"} className={classes.typeGrid}>
            <Typography variant={"body2"} className={classes.text}>
              {text.type}: {props.type}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default MarketplaceCard;
